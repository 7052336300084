@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family:'Rubik', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1C2127;
  scroll-behavior: smooth
}


@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.inner-circle{
  background: linear-gradient(225deg, rgba(255, 255, 255, 0.44) 15.62%, rgba(255, 255, 255, 0) 38.75%);
opacity: 0.1;
}

.background-color{
  background: rgba(45, 114, 210, 0.1);
  opacity: 0.2;
}

.b{
  border: 1px solid red;
}

input:focus{
  box-shadow: none;
  outline: none;
  border: 1px solid white;
}

*:focus{
  box-shadow: none;
  outline: none;
}

* {
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
}

*::placeholder {

  color: #C5CBD3 !important;

}
